import React from 'react';

const ServiciosTraducciones = () => {
  return (
    <>
    <section className="dHctcmVsYXRpdmU dHctcHgtNA dHctcHktMTY dHctb3ZlcmZsb3ctaGlkZGVu lg:dHctcHktMjA sm:dHctcHgtNg lg:dHctcHgtOA dHctYmctZ3JhZGllbnQtdG8tYg dHctZnJvbS13aGl0ZQ dHctdmlhLXB1cnBsZS0yNQ dHctdG8td2hpdGU">
    <img alt="" fetchpriority="high" width="300" height="264" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctcmlnaHQtMA dHctaC1hdXRv dHctdHJhbnNmb3Jt dHctb3BhY2l0eS0yNQ 
        dHctdy03Mg lg:dHctb3BhY2l0eS00MA dHctdG9wLTEy" src="/static/media/dots-large-grid.1230c423.svg" style={{ color: 'transparent' }} />
    <img alt="" fetchpriority="high" width="300" height="264" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaC1hdXRv dHctdHJhbnNmb3Jt dHctb3BhY2l0eS00MA LXR3LWxlZnQtMzY 
    dHctYm90dG9tLTM2 dHctdy03Mg" src="/static/media/dots-large-grid.1230c423.svg" style={{ color: 'transparent' }} /><div className="dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctc2NyZWVuLXhs lg:dHctZ3JpZA lg:dHctZ3JpZC1jb2xzLTEy lg:dHctZ2FwLTg"><div className="dHctZmxleA dHctZmxleC1jb2w lg:dHctY29sLXNwYW4tNQ"><div><span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">
        Traducción</span></div><div className="dHctcmVsYXRpdmU"><h1 className="dHctbWF4LXcteGw dHctbXQtNA dHctdGV4dC1wdXJwbGUtOTAw sm:dHctbXQtNQ h1 lg:dHctbWF4LXctbm9uZQ h2">
        Traducción de Cursos Multilingües</h1>
    <img alt="" loading="lazy" width="216" height="61" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaGlkZGVu dHctdHJhbnNmb3Jt LXR3LXRvcC0z xl:dHctYmxvY2s md:LXR3LXJpZ2h0LTg 
        dHctdy0xNA md:dHctdy0zMg dHctcm90YXRlLTEy" 
        src="/static/media/arrow-right-over.97d787e9.svg" 
        style={{ color: 'transparent' }} /></div>
        <p className="dHctbWF4LXctMnhs dHctbXQtMw dHctdGV4dC14bA dHctbGVhZGluZy1sb29zZQ dHctdGV4dC1wdXJwbGUtODAw sm:dHctbXQtNA">
        En Unilearn, ofrecemos servicios de traducción de cursos utilizando tecnología de IA generativa para proporcionar traducciones rápidas y precisas en formatos como IMS Common Cartridge, SCORM, HTML, XML, DOCX, PDF y videos. Nuestro equipo garantiza que todos los materiales mantengan su integridad y funcionalidad, adaptándolos culturalmente para audiencias globales. Con Unilearn, puedes expandir tu alcance y ofrecer experiencias de aprendizaje multilingües sin comprometer la calidad.
        </p>
            <div className="dHctbXQtMTA dHctZm9udC1zZW1pYm9sZA lg:dHctbXQtMTI">
            <p className="dHctdGV4dC1wdXJwbGUtODAw">¿Agendamos una reunión?</p>
            <a className="dHctZm9udC1tZWRpdW0 dHctcmVsYXRpdmU dHctbGVhZGluZy1ub3JtYWw dHctaW5saW5lLWZsZXg dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ
             dHctcm91bmRlZC1mdWxs dHctb3V0bGluZS1ub25l dHctZ3JvdXA dHctdGV4dC1wdXJwbGUtOTAw dHctYmcteWVsbG93LTUwMA hover:dHctYmcteWVsbG93LTYwMA dHctcHgtNQ dHctcHktMg.5 dHctdGV4dC1iYXNl 
             dHctbXQtMg sm:dHctbXQtMw" href="/contactenos">Contáctenos<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctbWwtMw group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ"><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg></a></div></div><div className="dHctcmVsYXRpdmU dHctbXQtMTI sm:dHctbXQtMTY lg:dHctbXQtMA xl:dHctcGwtMTI lg:dHctY29sLXNwYW4tNw"><div className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctbXgtYXV0bw sm:dHctZ3JpZA sm:dHctZ2FwLTY md:dHctbWF4LXctM3hs lg:dHctZ2FwLTU lg:dHctbWF4LXctbGc sm:dHctZ3JpZC1jb2xzLTQ lg:dHctZ3JpZC1jb2xzLTI dHctYXV0by1yb3dzLWZy"><a className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctdy1mdWxs dHctcC04 dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt dHctc2hhZG93LWxn sm:dHctY29sLXNwYW4tMg lg:dHctY29sLXNwYW4tMQ dHctcm91bmRlZC0yeGw hover:dHctc2NhbGUtMTA1 dHctZ3JvdXA dHctYmctcHVycGxlLTUw" href="#0"><div className="dHctZmxleC0x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy0xMA dHctaC0xMA dHctdGV4dC1wdXJwbGUtNzAw"><path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10"></path><path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2"></path></svg>
            <p className="dHctbXQtNA dHctdGV4dC14bA dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtOTAw">Traducción de Cursos para Canvas, Moodle y Formatos Estándar</p>
            <p className="dHctbXQtMg dHctdGV4dC1zbQ dHctdGV4dC1wdXJwbGUtODAw">Traducción precisa, adaptación cultural, compatibilidad de plataformas.</p>
        </div><div className="dHctZmxleA dHctanVzdGlmeS1lbmQ dHctdy1mdWxs dHctbXQtNg"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctdGV4dC1wdXJwbGUtNTAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ hover:dHctdGV4dC1wdXJwbGUtNjAw"><path d="M7 7l5 5l-5 5"></path><path d="M13 7l5 5l-5 5"></path></svg></div></a><a className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctdy1mdWxs dHctcC04 dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt dHctc2hhZG93LWxn sm:dHctY29sLXNwYW4tMg lg:dHctY29sLXNwYW4tMQ dHctcm91bmRlZC0yeGw hover:dHctc2NhbGUtMTA1 dHctZ3JvdXA dHctbXQtNg sm:dHctbXQtMA lg:dHctdHJhbnNsYXRlLXktMjg dHctYmcteWVsbG93LTIwMA" href="#0"><div className="dHctZmxleC0x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy0xMA dHctaC0xMA dHctdGV4dC1wdXJwbGUtNzAw"><path d="M3.5 5.5l1.5 1.5l2.5 -2.5"></path><path d="M3.5 11.5l1.5 1.5l2.5 -2.5"></path><path d="M3.5 17.5l1.5 1.5l2.5 -2.5"></path><path d="M11 6l9 0"></path><path d="M11 12l9 0"></path><path d="M11 18l9 0"></path></svg><p className="dHctbXQtNA dHctdGV4dC14bA dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtOTAw">
        IMS Common Cartridge (IMSCC)</p>
            <p className="dHctbXQtMg dHctdGV4dC1zbQ dHctdGV4dC1wdXJwbGUtODAw">
                            Desde la estructura del curso hasta cuestionarios, tareas y discusiones, facilitando la localización de los cursos para cualquier LMS.
            </p></div><div className="dHctZmxleA dHctanVzdGlmeS1lbmQ dHctdy1mdWxs dHctbXQtNg"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
                    fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctdGV4dC1wdXJwbGUtNTAw 
                    dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ hover:dHctdGV4dC1wdXJwbGUtNjAw">
                        <path d="M7 7l5 5l-5 5"></path><path d="M13 7l5 5l-5 5"></path></svg></div></a>
                        <a className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctdy1mdWxs dHctcC04 dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt dHctc2hhZG93LWxn 
                        sm:dHctY29sLXNwYW4tMg lg:dHctY29sLXNwYW4tMQ dHctcm91bmRlZC0yeGw hover:dHctc2NhbGUtMTA1 dHctZ3JvdXA dHctbXQtNg sm:dHctbXQtMA dHctYmctcm9zZS01MA" 
                        href="#0">
                            <div className="dHctZmxleC0x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" 
                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy0xMA dHctaC0xMA dHctdGV4dC1wdXJwbGUtNzAw"><path d="M13.62 8.382l1.966 -1.967a2 2 0 1 1 3.414 -1.415a2 2 0 1 1 -1.413 3.414l-1.82 1.821"></path><path d="M5.904 18.596c2.733 2.734 5.9 4 7.07 2.829c1.172 -1.172 -.094 -4.338 -2.828 -7.071c-2.733 -2.734 -5.9 -4 -7.07 -2.829c-1.172 1.172 .094 4.338 2.828 7.071z"></path><path d="M7.5 16l1 1"></path><path d="M12.975 21.425c3.905 -3.906 4.855 -9.288 2.121 -12.021c-2.733 -2.734 -8.115 -1.784 -12.02 2.121"></path></svg>
                            <p className="dHctbXQtNA dHctdGV4dC14bA dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtOTAw">
                                Paquetes SCORM</p>
                            <p className="dHctbXQtMg dHctdGV4dC1zbQ dHctdGV4dC1wdXJwbGUtODAw">
                                        Estándares SCORM 1.2 o SCORM 2004, garantizamos que cada objeto de aprendizaje, evaluación e interacción esté traducido de manera precisa.
                                </p></div><div className="dHctZmxleA dHctanVzdGlmeS1lbmQ dHctdy1mdWxs dHctbXQtNg"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctdGV4dC1wdXJwbGUtNTAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ hover:dHctdGV4dC1wdXJwbGUtNjAw"><path d="M7 7l5 5l-5 5"></path><path d="M13 7l5 5l-5 5"></path></svg></div></a><a className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctdy1mdWxs dHctcC04 dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt dHctc2hhZG93LWxn sm:dHctY29sLXNwYW4tMg lg:dHctY29sLXNwYW4tMQ dHctcm91bmRlZC0yeGw hover:dHctc2NhbGUtMTA1 dHctZ3JvdXA dHctbXQtNg sm:dHctbXQtMA lg:dHctdHJhbnNsYXRlLXktMjg dHctYmctdGVhbC01MA" href="#0"><div className="dHctZmxleC0x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy0xMA dHctaC0xMA dHctdGV4dC1wdXJwbGUtNzAw"><path d="M12 12m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0"></path><path d="M12 7v-4"></path><path d="M11 3h2"></path><path d="M15.536 8.464l2.828 -2.828"></path><path d="M17.657 4.929l1.414 1.414"></path><path d="M17 12h4"></path><path d="M21 11v2"></path><path d="M15.535 15.536l2.829 2.828"></path><path d="M19.071 17.657l-1.414 1.414"></path><path d="M12 17v4"></path><path d="M13 21h-2"></path><path d="M8.465 15.536l-2.829 2.828"></path><path d="M6.343 19.071l-1.413 -1.414"></path><path d="M7 12h-4"></path><path d="M3 13v-2"></path><path d="M8.464 8.464l-2.828 -2.828"></path><path d="M4.929 6.343l1.414 -1.413"></path></svg>
                            <p className="dHctbXQtNA dHctdGV4dC14bA dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtOTAw">XML y HTML</p>
                            <p className="dHctbXQtMg dHctdGV4dC1zbQ dHctdGV4dC1wdXJwbGUtODAw">Traducimos contenido y preservamos la estructura y la integridad de tu código, asegurando que los elementos interactivos, enlaces y metadatos funcionen perfectamente.</p></div>
                            <div className="dHctZmxleA dHctanVzdGlmeS1lbmQ dHctdy1mdWxs dHctbXQtNg"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctdGV4dC1wdXJwbGUtNTAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ hover:dHctdGV4dC1wdXJwbGUtNjAw"><path d="M7 7l5 5l-5 5"></path><path d="M13 7l5 5l-5 5"></path></svg></div></a><a className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctdy1mdWxs dHctcC04 dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt dHctc2hhZG93LWxn sm:dHctY29sLXNwYW4tMg lg:dHctY29sLXNwYW4tMQ dHctcm91bmRlZC0yeGw hover:dHctc2NhbGUtMTA1 dHctZ3JvdXA dHctbXQtNg sm:dHctbXQtMA sm:dHctY29sLXN0YXJ0LTI dHctYmctYmx1ZS01MA" href="/"><div className="dHctZmxleC0x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy0xMA dHctaC0xMA dHctdGV4dC1wdXJwbGUtNzAw"><path d="M15 8h.01"></path><path d="M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z"></path><path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5"></path><path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l3 3"></path></svg>
                            <p className="dHctbXQtNA dHctdGV4dC14bA dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtOTAw">Documentos DOCX, PDF, Videos y otros</p>
                            <p className="dHctbXQtMg dHctdGV4dC1zbQ dHctdGV4dC1wdXJwbGUtODAw">No importa el tipo de formato, nuestro servicio se adapta para mantener la riqueza de tus recursos.</p></div><div className="dHctZmxleA dHctanVzdGlmeS1lbmQ dHctdy1mdWxs dHctbXQtNg"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctdGV4dC1wdXJwbGUtNTAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ hover:dHctdGV4dC1wdXJwbGUtNjAw"><path d="M7 7l5 5l-5 5"></path><path d="M13 7l5 5l-5 5"></path></svg></div></a></div></div></div>
        </section>

   
   <section className="dHctcHgtNA dHctb3ZlcmZsb3ctaGlkZGVu dHctYmctd2hpdGU dHctcGItMjg sm:dHctcGItMzY sm:dHctcHgtNg lg:dHctcHgtOA">
        <div className="dHctbWF4LXctc2NyZWVuLXhs dHctbXgtYXV0bw"><div className="dHctcHgtNA dHctdGV4dC1jZW50ZXI sm:dHctcHgtNg lg:dHctcHgtOA"><h3 className="dHctdGV4dC1wdXJwbGUtOTAw h2"><span className="dHctYmxvY2s">
        Servicios de Traducción de Cursos de Unilearn</span><span className="dHctcmVsYXRpdmU dHctYmxvY2s"><span className="dHctcmVsYXRpdmU">
            <img alt="" loading="lazy" width="1126" height="64" decoding="async" data-nimg="1" 
                        className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctdHJhbnNmb3Jt dHctdHJhbnNsYXRlLXktOQ sm:dHctdHJhbnNsYXRlLXktMTA 
                                        xl:dHctdHJhbnNsYXRlLXktMTI" 
                        src="/_next/static/media/underline-simple-light-purple.ff6e5b29.svg" style={{ color: 'transparent' }} /><span className="dHctcmVsYXRpdmU">RPA, Transformación Digital</span></span></span></h3></div>
                        
 
 
 
 
 
<div className="dHctZ3JpZA dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMjA lg:dHctbWF4LXctbm9uZQ sm:dHctbXQtMjQ lg:dHctbXQtNDQ lg:dHctZ3JpZC1jb2xzLTEy dHctZ2FwLTE0 sm:dHctZ2FwLTE2 lg:dHctZ2FwLTg"><div className="dHctcmVsYXRpdmU dHctei0xMA dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctb3JkZXItMg lg:dHctY29sLXNwYW4tNg lg:dHctdGV4dC1sZWZ0"><div><span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">Datos del CLiente</span></div><div><h1 className="dHctbXQtMw.5 dHctZm9udC1ib2xk dHctdGV4dC1wdXJwbGUtOTAw h3">
            Traducción de Cursos para Canvas, Moodle y formátos estándares.</h1>
                <p className="dHctbWF4LXcteGw dHctbXQtMw dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw sm:dHctbGVhZGluZy1yZWxheGVk sm:dHctdGV4dC14bA">
                En Unilearn, ofrecemos servicios especializados en la traducción de cursos diseñados para plataformas como Canvas y Moodle, así como otros formatos estándar de la industria. Nuestro equipo asegura que todos los elementos del curso, incluyendo módulos, cuestionarios y recursos, sean traducidos con precisión y adaptados culturalmente, garantizando una experiencia de aprendizaje fluida y accesible para audiencias globales. Con nuestra tecnología de IA generativa y expertos en educación, transformamos tus materiales en contenido multilingüe de alta calidad que cumple con los estándares del sector.
                </p></div></div><div className="dHctcmVsYXRpdmU dHctb3JkZXItMQ dHctdy1mdWxs dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctbm9uZQ lg:dHctbXgtMA lg:dHctY29sLXNwYW4tNg lg:dHctZmxleA lg:dHctaXRlbXMtY2VudGVy">
                    <div className="dHctaGlkZGVu lg:dHctYmxvY2s">
            <img alt="" loading="lazy" width="433" height="403" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctdy1mdWxs dHctaC1mdWxs dHctdHJhbnNmb3Jt lg:dHctc2NhbGUtMTM1" src="/static/media/blob-light-purple.fea8cd27.svg" style={{ color: 'transparent' }}
            /></div>
            <img alt="" loading="lazy" width="300" height="538" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctdG9wLTA dHctb3JpZ2luLXRvcA dHctdHJhbnNmb3Jt LXR3LXRyYW5zbGF0ZS14LTEvMg LXR3LXRyYW5zbGF0ZS15LTg dHctbGVmdC0xLzI lg:dHctaGlkZGVu sm:dHctc2NhbGUtMTAw dHctc2NhbGUtODA" src="/static/media/dots-large-strip.80b8337d.svg" 
                    style={{ color: 'transparent' }} /><div className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctbXgtYXV0bw dHctc2hhZG93LWxn dHctcm91bmRlZC0zeGw lg:dHctbWF4LXctbGc lg:dHctbXgtMA"><div className="dHctcmVsYXRpdmU dHctYmxvY2s dHctdy1mdWxs">
            <img alt="" loading="lazy" width="600" height="511" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctei0xMA dHctaGlkZGVu dHctdy00MA dHctdHJhbnNmb3Jt lg:dHctYmxvY2s LXR3LXRvcC0yMA xl:dHctdy00OA xl:LXR3LXRvcC0yMA LXR3LXJpZ2h0LTIw" src="/static/media/dots.80e6656f.svg" style={{ color: 'transparent' }} />
            <figure className="dHctcmVsYXRpdmU dHctYXNwZWN0LQ[12/10] md:dHctb3JkZXItMQ">
            <img alt="Child painting" loading="lazy" decoding="async" data-nimg="fill" 
            className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctb2JqZWN0LWNlbnRlcg dHctdy1mdWxs dHctaC1mdWxs dHctc2hhZG93LXhs dHctcm91bmRlZC0zeGw" 
            sizes="(min-width: 1024px) 32rem, (min-width: 576px) 36rem, 100vw" 
            src="/static/media/images/selective-focus-of-human-and-robot-hands-typing-on-2023-11-27-05-28-22-utc.jpg" 
            style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }}
            /></figure></div></div></div>
            
</div>
 
                        <div className="dHctZ3JpZA dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMjA lg:dHctbWF4LXctbm9uZQ sm:dHctbXQtMjQ lg:dHctbXQtNDQ lg:dHctZ3JpZC1jb2xzLTEy 
    dHctZ2FwLTE0 sm:dHctZ2FwLTE2 lg:dHctZ2FwLTg">
        <div className="dHctcmVsYXRpdmU dHctei0xMA dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctb3JkZXItMg lg:dHctY29sLXNwYW4tNg 
        lg:dHctdGV4dC1sZWZ0 lg:dHctb3JkZXItMQ"><div>
            <span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA
             dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">
        100% en la Nube</span></div><div>
                                            <h1 className="dHctbXQtMw.5 dHctZm9udC1ib2xk dHctdGV4dC1wdXJwbGUtOTAw h3">
                                            IMS Common Cartridge (IMSCC)                                            </h1>
                                            <p className="dHctbWF4LXcteGw dHctbXQtMw dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw sm:dHctbGVhZGluZy1yZWxheGVk sm:dHctdGV4dC14bA">
                                            En Unilearn, ofrecemos traducciones completas para paquetes de IMS Common Cartridge (IMSCC), que son utilizados ampliamente en entornos de aprendizaje digital. Esto incluye la traducción de la estructura del curso, que abarca desde el diseño de módulos y lecciones hasta la integración de cuestionarios, tareas y foros de discusión. Nuestro enfoque garantiza que cada componente se traduzca con precisión, manteniendo la coherencia del contenido. Al facilitar la localización de los cursos, aseguramos que los materiales se adapten a diferentes contextos culturales y lingüísticos, lo que permite una implementación sin problemas en cualquier Learning Management System (LMS). Además, nos aseguramos de que todas las interacciones del estudiante sean fluidas y funcionales tras la traducción, proporcionando un entorno de aprendizaje eficaz y atractivo.
                                        </p></div></div>
                    <div className="dHctcmVsYXRpdmU dHctb3JkZXItMQ dHctdy1mdWxs dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctbm9uZQ lg:dHctbXgtMA lg:dHctY29sLXNwYW4tNg 
                    lg:dHctZmxleA lg:dHctaXRlbXMtY2VudGVy lg:dHctb3JkZXItMg">
                <div className="dHctaGlkZGVu lg:dHctYmxvY2s">
                            <img alt="" loading="lazy" width="433" height="403" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctdy1mdWxs dHctaC1mdWxs dHctdHJhbnNmb3Jt 
                            lg:dHctc2NhbGUtMTM1" src="/static/media/blob-light-yellow.f847cd46.svg" style={{ color: 'transparent' }} /></div>
                    <img alt="" loading="lazy" width="300" height="538" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctdG9wLTA dHctb3JpZ2luLXRvcA 
                        dHctdHJhbnNmb3Jt LXR3LXRyYW5zbGF0ZS14LTEvMg LXR3LXRyYW5zbGF0ZS15LTg dHctbGVmdC0xLzI lg:dHctaGlkZGVu sm:dHctc2NhbGUtMTAw dHctc2NhbGUtODA" 
                        src="/static/media/dots-large-strip.80b8337d.svg" style={{ color: 'transparent' }} /><div className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctbXgtYXV0bw dHctc2hhZG93LWxn dHctcm91bmRlZC0zeGw lg:dHctbWF4LXctbGc lg:dHctbXItMA lg:dHctbWwtYXV0bw"><div className="dHctcmVsYXRpdmU dHctYmxvY2s dHctdy1mdWxs">
        
        
            <img alt="" loading="lazy" width="600" height="511" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctei0xMA dHctaGlkZGVu dHctdy00MA dHctdHJhbnNmb3Jt lg:dHctYmxvY2s 
                            LXR3LXRvcC0yMA xl:dHctdy00OA xl:LXR3LXRvcC0yMA LXR3LWxlZnQtMjA" src="/static/media/dots.80e6656f.svg" style={{ color: 'transparent' }} />
                            <figure className="dHctcmVsYXRpdmU dHctYXNwZWN0LQ[12/10] md:dHctb3JkZXItMQ">
                         <img alt="Child covered in paint" loading="lazy" decoding="async" data-nimg="fill" 
                         className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctb2JqZWN0LWNlbnRlcg dHctdy1mdWxs 
                    dHctaC1mdWxs dHctc2hhZG93LXhs dHctcm91bmRlZC0zeGw" sizes="(min-width: 1024px) 32rem, (min-width: 576px) 36rem, 100vw" 
                     src="/static/media/images/opened-hard-disk-after-disassembly-during-repair-o-2023-11-27-04-53-01-utc.jpg" 
                    style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }}/></figure></div></div></div>


</div>

<div className="dHctZ3JpZA dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMjA lg:dHctbWF4LXctbm9uZQ sm:dHctbXQtMjQ lg:dHctbXQtNDQ lg:dHctZ3JpZC1jb2xzLTEy dHctZ2FwLTE0 sm:dHctZ2FwLTE2 lg:dHctZ2FwLTg"><div className="dHctcmVsYXRpdmU dHctei0xMA dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctb3JkZXItMg lg:dHctY29sLXNwYW4tNg lg:dHctdGV4dC1sZWZ0"><div><span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">Datos del CLiente</span></div><div><h1 className="dHctbXQtMw.5 dHctZm9udC1ib2xk dHctdGV4dC1wdXJwbGUtOTAw h3">
Paquetes SCORM y LTI.</h1>
                <p className="dHctbWF4LXcteGw dHctbXQtMw dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw sm:dHctbGVhZGluZy1yZWxheGVk sm:dHctdGV4dC14bA">
                En Unilearn, ofrecemos un servicio integral de traducción para Paquetes SCORM y Learning Tools Interoperability (LTI), asegurando que tu contenido de e-learning mantenga su funcionalidad y efectividad en cualquier idioma. Para los Paquetes SCORM, garantizamos que cada objeto de aprendizaje, evaluación e interacción se traduzca con precisión, preservando la integridad del contenido y la interactividad del curso en entornos de aprendizaje digital. Simultáneamente, adaptamos y traducimos herramientas integradas a través de LTI, asegurando que todas las herramientas externas sean compatibles con las plataformas de aprendizaje y que proporcionen una experiencia de usuario fluida y coherente. Nuestro enfoque meticuloso asegura que tanto los elementos SCORM como los LTI funcionen perfectamente, permitiendo a los educadores ofrecer cursos multilingües de alta calidad sin comprometer la experiencia del aprendiz.                </p></div></div><div className="dHctcmVsYXRpdmU dHctb3JkZXItMQ dHctdy1mdWxs dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctbm9uZQ lg:dHctbXgtMA lg:dHctY29sLXNwYW4tNg lg:dHctZmxleA lg:dHctaXRlbXMtY2VudGVy"><div className="dHctaGlkZGVu lg:dHctYmxvY2s">
            <img alt="" loading="lazy" width="433" height="403" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctdy1mdWxs dHctaC1mdWxs dHctdHJhbnNmb3Jt lg:dHctc2NhbGUtMTM1" src="/static/media/blob-light-purple.fea8cd27.svg" style={{ color: 'transparent' }}
            /></div>
            <img alt="" loading="lazy" width="300" height="538" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctdG9wLTA dHctb3JpZ2luLXRvcA dHctdHJhbnNmb3Jt LXR3LXRyYW5zbGF0ZS14LTEvMg LXR3LXRyYW5zbGF0ZS15LTg dHctbGVmdC0xLzI lg:dHctaGlkZGVu sm:dHctc2NhbGUtMTAw dHctc2NhbGUtODA" src="/static/media/dots-large-strip.80b8337d.svg" 
                    style={{ color: 'transparent' }} /><div className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctbXgtYXV0bw dHctc2hhZG93LWxn dHctcm91bmRlZC0zeGw lg:dHctbWF4LXctbGc lg:dHctbXgtMA"><div className="dHctcmVsYXRpdmU dHctYmxvY2s dHctdy1mdWxs">
            <img alt="" loading="lazy" width="600" height="511" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctei0xMA dHctaGlkZGVu dHctdy00MA dHctdHJhbnNmb3Jt lg:dHctYmxvY2s LXR3LXRvcC0yMA xl:dHctdy00OA xl:LXR3LXRvcC0yMA LXR3LXJpZ2h0LTIw" src="/static/media/dots.80e6656f.svg" style={{ color: 'transparent' }} />
            <figure className="dHctcmVsYXRpdmU dHctYXNwZWN0LQ[12/10] md:dHctb3JkZXItMQ">
            <img alt="Child painting" loading="lazy" decoding="async" data-nimg="fill" 
            className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctb2JqZWN0LWNlbnRlcg dHctdy1mdWxs dHctaC1mdWxs dHctc2hhZG93LXhs dHctcm91bmRlZC0zeGw" 
            sizes="(min-width: 1024px) 32rem, (min-width: 576px) 36rem, 100vw" 
            src="/static/media/images/mature-multi-ethnic-couple-happy-and-in-love-while-2023-11-27-04-55-15-utc.jpg" 
            style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }}
            /></figure></div></div></div>
            
</div>

<div className="dHctZ3JpZA dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMjA lg:dHctbWF4LXctbm9uZQ sm:dHctbXQtMjQ lg:dHctbXQtNDQ lg:dHctZ3JpZC1jb2xzLTEy dHctZ2FwLTE0 sm:dHctZ2FwLTE2 lg:dHctZ2FwLTg"><div className="dHctcmVsYXRpdmU dHctei0xMA dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctb3JkZXItMg lg:dHctY29sLXNwYW4tNg lg:dHctdGV4dC1sZWZ0 lg:dHctb3JkZXItMQ"><div><span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">Seguridad</span></div><div><h1 className="dHctbXQtMw.5 dHctZm9udC1ib2xk dHctdGV4dC1wdXJwbGUtOTAw h3">
                Optimización del Costo Total de Propiedad (TCO) en Traducción de Cursos con Unilearn</h1>
                <p className="dHctbWF4LXcteGw dHctbXQtMw dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw sm:dHctbGVhZGluZy1yZWxheGVk sm:dHctdGV4dC14bA">
                Al elegir Unilearn para la traducción de cursos, las instituciones educativas y las empresas pueden optimizar su Costo Total de Propiedad (TCO) de manera significativa. Primero, no es necesario contratar traductores internos, lo que elimina gastos salariales y costos de formación. Además, gracias a nuestra tecnología de IA generativa, ofrecemos traducciones rápidas y precisas, lo que permite un lanzamiento más ágil de cursos en el mercado.</p></div></div><div className="dHctcmVsYXRpdmU dHctb3JkZXItMQ dHctdy1mdWxs dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctbm9uZQ lg:dHctbXgtMA lg:dHctY29sLXNwYW4tNg lg:dHctZmxleA lg:dHctaXRlbXMtY2VudGVy lg:dHctb3JkZXItMg"><div className="dHctaGlkZGVu lg:dHctYmxvY2s">
            <img alt="" loading="lazy" width="398" height="374" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctdy1mdWxs dHctaC1mdWxs dHctdHJhbnNmb3Jt lg:dHctc2NhbGUtMTM1" 
            src="/static/media/blob-light-rose.49747274.svg" style={{ color: 'transparent' }} /></div>
            <img alt="" 
                loading="lazy" width="300" height="538" decoding="async" data-nimg="1" 
                className="dHctYWJzb2x1dGU dHctdG9wLTA dHctb3JpZ2luLXRvcA dHctdHJhbnNmb3Jt LXR3LXRyYW5zbGF0ZS14LTEvMg LXR3LXRyYW5zbGF0ZS15LTg dHctbGVmdC0xLzI 
                        lg:dHctaGlkZGVu sm:dHctc2NhbGUtMTAw dHctc2NhbGUtODA" 
                src="/static/media/dots-large-strip.80b8337d.svg" 
                style={{ color: 'transparent' }} />
                <div className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctbXgtYXV0bw dHctc2hhZG93LWxn dHctcm91bmRlZC0zeGw lg:dHctbWF4LXctbGc lg:dHctbXItMA lg:dHctbWwtYXV0bw"><div className="dHctcmVsYXRpdmU dHctYmxvY2s dHctdy1mdWxs">
            <img alt="" loading="lazy" width="600" height="511" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctei0xMA dHctaGlkZGVu dHctdy00MA dHctdHJhbnNmb3Jt lg:dHctYmxvY2s 
                        LXR3LXRvcC0yMA xl:dHctdy00OA xl:LXR3LXRvcC0yMA LXR3LWxlZnQtMjA" src="/static/media/dots.80e6656f.svg" style={{ color: 'transparent' }} />
                        <figure className="dHctcmVsYXRpdmU dHctYXNwZWN0LQ[12/10] md:dHctb3JkZXItMQ">
                            <img alt="Child working on workbook" loading="lazy" decoding="async" data-nimg="fill" 
                            className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctb2JqZWN0LWNlbnRlcg dHctdy1mdWxs 
                            dHctaC1mdWxs dHctc2hhZG93LXhs dHctcm91bmRlZC0zeGw" sizes="(min-width: 1024px) 32rem, (min-width: 576px) 36rem, 100vw" 
                            src="/static/media/images/cool-calm-and-casual-studio-shot-of-a-diverse-gr-2023-11-27-05-25-53-utc.jpg" 
                            style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }}
                         /></figure></div></div></div></div>
                         
                    <div className="dHctZ3JpZA dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMjA lg:dHctbWF4LXctbm9uZQ sm:dHctbXQtMjQ lg:dHctbXQtNDQ 
                         lg:dHctZ3JpZC1jb2xzLTEy dHctZ2FwLTE0 sm:dHctZ2FwLTE2 lg:dHctZ2FwLTg">
                            <div className="dHctcmVsYXRpdmU dHctei0xMA dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctb3JkZXItMg lg:dHctY29sLXNwYW4tNg lg:dHctdGV4dC1sZWZ0">
                                <div><span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">
                                    Cero Infraestructura</span></div><div><h1 className="dHctbXQtMw.5 dHctZm9udC1ib2xk dHctdGV4dC1wdXJwbGUtOTAw h3">
                         No es necesario invertir en infraestructura de ningún tipo.</h1>
                <p className="dHctbWF4LXcteGw dHctbXQtMw dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw sm:dHctbGVhZGluZy1yZWxheGVk sm:dHctdGV4dC14bA">
                Con nuestra solución cloud, eliminamos la necesidad de invertir en infraestructura física como servidores, redes, o centros de datos. Todo está alojado en la nube, lo que no solo reduce los costos iniciales, sino que también disminuye los gastos operativos a largo plazo. No tendrás que preocuparte por el mantenimiento o la actualización de hardware, lo que libera recursos financieros y humanos que pueden ser redirigidos a otras áreas críticas de tu negocio.
                </p></div></div><div className="dHctcmVsYXRpdmU dHctb3JkZXItMQ dHctdy1mdWxs dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctbm9uZQ lg:dHctbXgtMA lg:dHctY29sLXNwYW4tNg lg:dHctZmxleA lg:dHctaXRlbXMtY2VudGVy"><div className="dHctaGlkZGVu lg:dHctYmxvY2s">
            <img alt="" loading="lazy" width="433" height="403" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctdy1mdWxs dHctaC1mdWxs dHctdHJhbnNmb3Jt lg:dHctc2NhbGUtMTM1" 
            src="/static/media/blob-light-purple.fea8cd27.svg" style={{ color: 'transparent' }}
            /></div>
            <img alt="" loading="lazy" width="300" height="538" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctdG9wLTA dHctb3JpZ2luLXRvcA dHctdHJhbnNmb3Jt LXR3LXRyYW5zbGF0ZS14LTEvMg LXR3LXRyYW5zbGF0ZS15LTg dHctbGVmdC0xLzI lg:dHctaGlkZGVu sm:dHctc2NhbGUtMTAw dHctc2NhbGUtODA" src="/static/media/dots-large-strip.80b8337d.svg" 
                    style={{ color: 'transparent' }} /><div className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctbXgtYXV0bw dHctc2hhZG93LWxn dHctcm91bmRlZC0zeGw lg:dHctbWF4LXctbGc lg:dHctbXgtMA"><div className="dHctcmVsYXRpdmU dHctYmxvY2s dHctdy1mdWxs">
            <img alt="" loading="lazy" width="600" height="511" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctei0xMA dHctaGlkZGVu dHctdy00MA dHctdHJhbnNmb3Jt lg:dHctYmxvY2s LXR3LXRvcC0yMA xl:dHctdy00OA xl:LXR3LXRvcC0yMA LXR3LXJpZ2h0LTIw" src="/static/media/dots.80e6656f.svg" style={{ color: 'transparent' }} />
            <figure className="dHctcmVsYXRpdmU dHctYXNwZWN0LQ[12/10] md:dHctb3JkZXItMQ">
            <img alt="Child painting" loading="lazy" decoding="async" data-nimg="fill" 
            className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctb2JqZWN0LWNlbnRlcg dHctdy1mdWxs dHctaC1mdWxs dHctc2hhZG93LXhs dHctcm91bmRlZC0zeGw" 
            sizes="(min-width: 1024px) 32rem, (min-width: 576px) 36rem, 100vw" 
            src="/static/media/images/clouds-and-beautiful-clear-blue-sky-in-summer-2023-11-27-05-23-24-utc.jpg" 
            style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }}
            /></figure></div></div></div>
            
</div>


                         
                         </div></section>

            </>
  );
}

export default ServiciosTraducciones;
