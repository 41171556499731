import React from 'react';

const ServiciosPrecios = () => {
  return (
    <section className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctcHgtNA dHctcHktMTY sm:dHctcHktMjQ sm:dHctcHgtNg xl:dHctcHgtOA">
      <div className="dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctc2NyZWVuLXhs"><div className="lg:dHctZ3JpZA lg:dHctZ3JpZC1jb2xzLTI md:dHctZ2FwLTE2 lg:dHctZ2FwLTA">
        <div className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctcHItMTA xl:dHctcHItMA">
          <div><span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">
            Planes y Precios LMS en la Nube</span></div>
          <h2 className="dHctbXQtMw.5 sm:dHctbXQtNA h2 dHctbWF4LXcteGw dHctdGV4dC1wdXJwbGUtOTAw">Precios de LMS en la Nube</h2>
          <p className="dHctbWF4LXctbGc dHctbXQtMw dHctdGV4dC1sZw dHctbGVhZGluZy1yZWxheGVk dHctdGV4dC1wdXJwbGUtODAw">
          Moodle es un sistema de gestión de aprendizaje de código abierto que proporciona herramientas flexibles para la educación en línea.</p>
          <p className="dHctbWF4LXctbGc dHctbXQtMw dHctdGV4dC1sZw dHctbGVhZGluZy1yZWxheGVk dHctdGV4dC1wdXJwbGUtODAw">
          Canvas es un sistema de gestión de aprendizaje basado en la nube que ofrece herramientas avanzadas para la educación en línea.</p>
          
          <div className="dHctbXQtOA dHctZm9udC1tZWRpdW0 lg:dHctbXQtMTA"><p className="dHctdGV4dC1wdXJwbGUtODAw">¿Deseas conocer más sobre nuestros planes?</p>
          <a href="/contactenos" className="dHctbXQtMQ.5 dHctZmxleA dHctaXRlbXMtY2VudGVy dHctcHktMA.5 dHctcHgtMA dHctdy0[126px] dHctbWF4LXctZnVsbA dHctbGVhZGluZy02 dHctdGV4dC1sZWZ0 dHctdGV4dC1wdXJwbGUtNjAw dHctbm8tdW5kZXJsaW5l
           dHctYmctdHJhbnNwYXJlbnQ dHctYm9yZGVyLWItMg dHctYm9yZGVyLXB1cnBsZS02MDA dHctYm9yZGVyLXNvbGlk dHctY3Vyc29yLXBvaW50ZXI hover:dHctdGV4dC1wdXJwbGUtNTAw dHctdHJhbnNpdGlvbg dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ 
           hover:dHctYm9yZGVyLXB1cnBsZS00MDA dHctZ3JvdXA">
            <span className="dHctdGV4dC1iYXNl dHctZm9udC1ib2xk dHctdGV4dC1sZWZ0">Contactanos</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctbWwtMw group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ"><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg></a></div></div><div className="dHctZ3JpZA dHctZ2FwLTg dHctbXQtMTQ lg:dHctbXQtMjA md:dHctZ3JpZC1jb2xzLTI lg:dHctZ2FwLTQ xl:dHctZ2FwLTg"><div className="dHctYmctcHVycGxlLTI1 dHctdy1mdWxs dHctcHgtNg dHctcHktMTA dHctcm91bmRlZC14bA lg:dHctcHgtNQ xl:dHctcHgtMTA"><div className="dHctcmVsYXRpdmU"><div className="dHctcmVsYXRpdmU dHctaW5saW5lLWJsb2Nr dHctdy1mdWxs dHctdGV4dC1sZWZ0">
              <h2 className="dHctcmVsYXRpdmU dHctdGV4dC14bA dHctZm9udC1ib2xk dHctdHJhY2tpbmctbm9ybWFs dHctdGV4dC1wdXJwbGUtOTAw h3">
                  LMS Moodle
              </h2>
              <p> precio desde:</p>
              <div className="dHctbXQtMg"><h3 className="dHctdGV4dC1wdXJwbGUtOTAw h2">$200.000</h3><div className="dHctbXQtMw">
                <div className="dHctaW5saW5lLWJsb2Nr dHctaC02 dHctcHgtMw dHctdGV4dC1zbQ dHctZm9udC1tZWRpdW0 dHctbGVhZGluZy02 dHctdGV4dC1wdXJwbGUtNzAw dHctYWxpZ24tdG9w 
                dHctYmctcHVycGxlLTIwMA LXR3LXJvdGF0ZS0x dHctcm91bmRlZC14bA">por mes</div></div>
                <p className="dHctYmxvY2s dHctdy1mdWxs dHctbXQtNg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtOTAw">Principales Características:</p></div>
                <ul className="dHctbXQtNA dHctc3BhY2UteS0y dHctdGV4dC1iYXNl">
                    <li className="dHctZmxleA dHctaXRlbXMtY2VudGVy"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="dHctdy01 dHctaC01 dHctdGV4dC1wdXJwbGUtNjAw">
                      <path d="M5 12l5 5l10 -10"></path></svg>
                      <span className="dHctbWwtMg dHctdGV4dC1wdXJwbGUtODAw">Acceso a cursos ilimitados</span></li>
                      <li className="dHctZmxleA dHctaXRlbXMtY2VudGVy"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="dHctdy01 dHctaC01 dHctdGV4dC1wdXJwbGUtNjAw">
                        <path d="M5 12l5 5l10 -10"></path></svg><span className="dHctbWwtMg dHctdGV4dC1wdXJwbGUtODAw">Soporte para contenido multimedia</span></li>
                        <li className="dHctZmxleA dHctaXRlbXMtY2VudGVy"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="dHctdy01 dHctaC01 dHctdGV4dC1wdXJwbGUtNjAw">
                        <path d="M5 12l5 5l10 -10"></path></svg><span className="dHctbWwtMg dHctdGV4dC1wdXJwbGUtODAw">Evaluaciones y cuestionarios</span></li>
                      
                      
                        </ul><a className="dHctZm9udC1tZWRpdW0 dHctcmVsYXRpdmU dHctbGVhZGluZy1ub3JtYWw dHctaW5saW5lLWZsZXg dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctcm91bmRlZC1mdWxs dHctb3V0bGluZS1ub25l dHctZ3JvdXA dHctdGV4dC13aGl0ZQ dHctYmctcHVycGxlLTYwMA hover:dHctYmctcHVycGxlLTUwMA dHctcHgtNQ dHctcHktMg.5 dHctdGV4dC1iYXNl dHctbXQtNg" href="#">Contáctenos<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="dHctdy01 dHctaC01 dHctbWwtMw group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ"><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg></a></div></div></div><div className="dHctYmcteWVsbG93LTIwMA lg:LXR3LXRyYW5zbGF0ZS15LTIw dHctdy1mdWxs dHctcHgtNg dHctcHktMTA dHctcm91bmRlZC14bA lg:dHctcHgtNQ xl:dHctcHgtMTA"><div className="dHctcmVsYXRpdmU"><div className="dHctcmVsYXRpdmU dHctaW5saW5lLWJsb2Nr dHctdy1mdWxs dHctdGV4dC1sZWZ0">
                        <h2 className="dHctcmVsYXRpdmU dHctdGV4dC14bA dHctZm9udC1ib2xk dHctdHJhY2tpbmctbm9ybWFs dHctdGV4dC1wdXJwbGUtOTAw h3">
                        LMS Canvas</h2>
                          <div className="dHctbXQtMg">
                            <h2 className="dHctdGV4dC1wdXJwbGUtOTAw h2">$150.000</h2><div className="dHctbXQtMw"><div className="dHctaW5saW5lLWJsb2Nr dHctaC02 dHctcHgtMw dHctdGV4dC1zbQ dHctZm9udC1tZWRpdW0 dHctbGVhZGluZy02 dHctdGV4dC1wdXJwbGUtNzAw
                             dHctYWxpZ24tdG9w dHctYmctcHVycGxlLTIwMA LXR3LXJvdGF0ZS0x dHctcm91bmRlZC14bA">por mes</div></div>
                             <p className="dHctYmxvY2s dHctdy1mdWxs dHctbXQtNg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtOTAw">Principales características:</p></div>
                             <ul className="dHctbXQtNA dHctc3BhY2UteS0y dHctdGV4dC1iYXNl">
                              <li className="dHctZmxleA dHctaXRlbXMtY2VudGVy"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" 
                              stroke-linecap="round" stroke-linejoin="round" className="dHctdy01 dHctaC01 dHctdGV4dC1wdXJwbGUtNjAw">
                                <path d="M5 12l5 5l10 -10"></path></svg><span className="dHctbWwtMg dHctdGV4dC1wdXJwbGUtODAw">Platafora LMS más utilizada por universidades</span></li>
                                <li className="dHctZmxleA dHctaXRlbXMtY2VudGVy"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" 
                                stroke-linecap="round" stroke-linejoin="round" className="dHctdy01 dHctaC01 dHctdGV4dC1wdXJwbGUtNjAw">
                                  <path d="M5 12l5 5l10 -10"></path></svg><span className="dHctbWwtMg dHctdGV4dC1wdXJwbGUtODAw">Evaluaciones y Cuestionarios Personalizables</span></li></ul><a className="dHctZm9udC1tZWRpdW0 dHctcmVsYXRpdmU dHctbGVhZGluZy1ub3JtYWw dHctaW5saW5lLWZsZXg dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctcm91bmRlZC1mdWxs dHctb3V0bGluZS1ub25l dHctZ3JvdXA dHctdGV4dC13aGl0ZQ dHctYmctcHVycGxlLTYwMA hover:dHctYmctcHVycGxlLTUwMA dHctcHgtNQ dHctcHktMg.5 dHctdGV4dC1iYXNl dHctbXQtNg" href="#">Contáctenos<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="dHctdy01 dHctaC01 dHctbWwtMw group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ"><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg></a></div></div></div></div></div></div></section>
    );
  };

export default ServiciosPrecios;
